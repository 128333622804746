const createSeoConfig = ({
  title,
  description,
  images,
  ...rest
}) => ({
  title,
  titleTemplate: "Rather | %s",
  description,
  canonical: "https://gorather.com",
  openGraph: {
    url: "https://gorather.com",
    title,
    description,
    images,
    site_name: "Rather",
  },
  ...rest,
});

export default createSeoConfig;
