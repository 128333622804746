import * as Sentry from "@sentry/node";
import { RewriteFrames } from "@sentry/integrations";

export const init = () => {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const integrations = [];
    if (
      process.env.NEXT_IS_SERVER === "true"
      && process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR
    ) {
      integrations.push(
        new RewriteFrames({
          iteratee: (frame) => {
            /* eslint-disable-next-line no-param-reassign */
            frame.filename = frame.filename.replace(
              process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
              "app:///",
            );
            /* eslint-disable-next-line no-param-reassign */
            frame.filename = frame.filename.replace(".next", "_next");
            return frame;
          },
        }),
      );
    }

    Sentry.init({
      enabled: process.env.NODE_ENV === "production",
      integrations,
      ignoreErrors: ["CANCELLED_NAVIGATION", "NetworkError"],
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      release: process.env.NEXT_PUBLIC_COMMIT_SHA,
    });
  }
};
