import createSeoConfig from "./createSeoConfig";

const createDefaultSeoConfig = () => createSeoConfig({
  title: "La red social de cinéfilos",
  description: "Encontrá recomendaciones de películas y series de gente con tus mismos gustos!",
  images: [
    {
      url: "https://gorather.com/assets/logo512x512.png",
      width: 512,
      height: 512,
      alt: "logo",
    },
    {
      url: "https://gorather.com/assets/logo32x32.png",
      width: 32,
      height: 32,
      alt: "logo",
    },
  ],
});

export default createDefaultSeoConfig;
