/* eslint-disable react/no-danger */
import { useEffect } from "react";
import { createSeoInjector, withDialogs, withToasts } from "hocs";
import { withAppState, withSession } from "store";
import { getClientsideHref } from "utils";
import withFacebookPixel from "next-fbq";
import defaultSeoConfig from "seo/app";
import * as Sentry from "utils/sentry";
import { DefaultSeo } from "next-seo";
import { withTheme } from "theme";
import NProgress from "nprogress";
import Router from "next/router";
import { withI18n } from "i18n";
import moment from "moment";
import "nprogress/nprogress.css";
import "global.css";

moment.locale("es");

Sentry.init();

NProgress.configure({ showSpinner: false });
Router.events.on("routeChangeError", () => NProgress.done());
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());

Router.events.on("hashChangeComplete", () => {
  const { hash } = getClientsideHref();
  if (hash) {
    const element = document.getElementById(hash);
    if (element) element.scrollIntoView({ behavior: "smooth", block: "center" });
  }
});

const App = ({ Component, pageProps }) => {
  useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) jssStyles.parentNode.removeChild(jssStyles);
  }, []);
  
  const getLayout = Component.getLayout || ((page) => page);

  return getLayout(<Component {...pageProps} />);
};

const withSeo = createSeoInjector(defaultSeoConfig, DefaultSeo);
const withFBQ = withFacebookPixel("205307724076009", Router);

const hocs = [
  withFBQ,
  withSeo,
  withToasts,
  withDialogs,
  withTheme,
  withSession,
  withAppState,
  withI18n,
];

const wrappedApp = hocs.reduce((app, wrapper) => wrapper(app), App);

export default wrappedApp;
